<template>
    <component :id="block.getId()" :is="blockComponent" v-if="isVisible" class="cms-block" @click="onSelected" @clicked="clicked" v-bind="blockComponentParams" :style="_blockStyle"
               :class="[{'selected': isSelected, 'editable': editable, 'fill': block.container !== 'contained'}, 'layout-' + block.mediaProxy.layout, 'cms-block-' + block.type, blockClass]" :no-body="noBody">
        <slot name="before-container"/>

        <cms-container :mode="block.container" :show-limit="isSelected && !isChild" :style="_containerStyle" :class="containerClass" :align="block.mediaProxy.align" :horizontal="horizontal"
                       @click.native="clicked" v-touch:tap="clicked" v-bind="containerComponentParams">
            <!-- @click.native is a Custom Event to trigger before navigation for search analytics -->
            <div v-if="editable && anyResponsiveFlag" class="editor-overlay">
                <i class="fas fa-eye-slash mr-1"/>
                <i v-if="!block.mediaQuery.kiosk.visible" class="fak fa-kiosk fa-fw" :title="'Hidden on Kiosk|Masqué sur kiosque'|tr" v-b-tooltip/>
                <i v-if="!block.mediaQuery.desktop.visible" class="fas fa-desktop fa-fw" :title="'Hidden on Desktop|Masqué sur ordinateur'|tr" v-b-tooltip/>
                <i v-if="!block.mediaQuery.tablet.visible" class="fas fa-tablet fa-fw" :title="'Hidden on Tablet|Masqué sur tablette'|tr" v-b-tooltip/>
                <i v-if="!block.mediaQuery.mobile.visible" class="fas fa-mobile fa-fw" :title="'Hidden on Mobile|Masqué sur téléphone'|tr" v-b-tooltip/>
            </div>
            <slot></slot>
        </cms-container>
        <slot name="after-container"/>
    </component>
</template>

<script>

// cms-block #hot-reload-debug
import CmsContainer from "@/components/editor/rendering/cms-container.vue";

export default {
    name: `cms-block`,
    components: {CmsContainer},
    props: {
        block: {type: Object, required: true},
        item: {type: Object},
        index: {type: Number},
        currentLanguage: {type: String, default: `fr`},
        noPadding: {type: Boolean},
        isChild: {type: Boolean},
        horizontal: {type: Boolean},
        blockSelection: {type: Array},
        align: {type: String, default: `inherit`},
        editable: {type: Boolean},
        blockClass: {type: [Object, Array, String], default: ``},
        blockComponent: {type: [Object, String], default: `div`},
        blockComponentParams: {type: Object},
        containerClass: {type: [Object, Array, String], default: ``},
        containerComponentParams: {type: Object},
        // for Card components
        noBody: {type: Boolean}
    },
    computed: {
        componentProps() {
            return {
                align: this.blockAlign,
                block: this.block,
                boundItem: this.boundItem,
                selected: this.isSelected
            }
        },
        boundItem() {
            if (this.item) {
                return this.item;
            }
            if (this.isEditMode && this.block.itemType === `universal-search`) {
                return this.cms.getUniversalSearchPreviewItem();
            } else if (this.cmsPage && this.block.itemType === `inherit`) {
                return this.cmsPage.item;
            } else {
                return this.block.item;
            }
        },
        boundItemList() {
            if (this.isEditMode && this.block.itemType === `universal-search`) {
                return [this.cms.getUniversalSearchPreviewItem()];
            } else if (this.block.itemType === `inherit`) {
                return this.cms.getItemsByType(this.cmsPage.itemType);
                // return this.cmsPage.itemList;
            } else {
                return this.block.itemList;
            }
        },
        blockAlign() {
            if (this.block.mediaProxy.align === `inherit`) {
                return this.align;
            } else {
                return this.block.mediaProxy.align;
            }
        },
        isSelected() {
            if (this.blockSelection) {
                return this.blockSelection.includes(this.block);
            } else {
                return false;
            }
        },
        isVisible() {
            if (!this.block.mediaQuery) {
                return true;
            } else if (this.isKioskDevice) {
                if (this.isWheelchairMode) {
                    return this.block.mediaQuery.wheelchair.visible && this.block.mediaQuery.kiosk.visible;
                } else {
                    return this.block.mediaQuery.kiosk.visible;
                }
            } else if (this.isTabletLayoutOrPreview) {
                return this.block.mediaQuery.tablet.visible;
            } else if (this.isMobileLayoutOrPreview) {
                return this.block.mediaQuery.mobile.visible;
            } else {
                return this.block.mediaQuery.desktop.visible;
            }
        },
        anyResponsiveFlag() {
            return !this.block.mediaQuery.kiosk.visible || !this.block.mediaQuery.mobile.visible || !this.block.mediaQuery.tablet.visible || !this.block.mediaQuery.desktop.visible;
        },
        _blockStyle() {
            let style = this.cmsWebsite.getColorSchemeVariables(this.block.mediaProxy.colorScheme);

            if (this.block.background === `color` && this.block.backgroundColor) {
                style[`--scheme-bg`] = this.cmsWebsite.getColor(this.block.backgroundColor);
                // if (bg === `transparent`) {
                //     style[`--scheme-bg`] = `var(--page-bg)`;
                // } else {
                //     style[`--scheme-bg`] = bg;
                // }
            }
            if (this.block.backgroundRadius && this.block.backgroundRadius !== `0`) {
                style[`--container-radius`] = this.block.backgroundRadius;
            }
            if (this.isMobileLayoutOrPreview) {
                style[`--block-padding-left`] = this.block.padding.leftMobile(this.website.body.blocks.style.mobile.padding);
                style[`--block-padding-top`] = this.block.padding.topMobile(this.website.body.blocks.style.mobile.padding);
                style[`--block-padding-right`] = this.block.padding.rightMobile(this.website.body.blocks.style.mobile.padding);
                style[`--block-padding-bottom`] = this.block.padding.bottomMobile(this.website.body.blocks.style.mobile.padding);
            } else {
                style[`--block-padding-left`] = `${this.block.padding.left}px`;
                style[`--block-padding-top`] = `${this.block.padding.top}px`;
                style[`--block-padding-right`] = `${this.block.padding.right}px`;
                style[`--block-padding-bottom`] = `${this.block.padding.bottom}px`;
            }

            return style;
        },
        _containerStyle() {
            let style = {};

            if (!this.noPadding) {
                if (this.isMobileLayoutOrPreview) {
                    style.padding = this.block.padding.toStringMobile(this.website.body.blocks.style.mobile.padding);
                } else {
                    style.padding = this.block.padding.toString();
                }
            }
            if (this.block.background === `image` && this.block.backgroundImage) {
                style.background = `url("${this.block.backgroundImage}") no-repeat center center`;
                style.backgroundSize = `cover`;
            }

            if (this.block.borders?.active) {
                style.borderWidth = this.block.borders.mediaProxy.width;
                style.borderColor = this.block.borders.mediaProxy.color;
                style.borderStyle = this.block.borders.mediaProxy.style;
            }
            if (this.block.shadow?.active) {
                style.boxShadow = this.block.shadow.toCss();
            }

            return style;
        }
    },
    methods: {
        clicked() {
            if (this.isEditMode)
                return null;

            // Special event for Analytics search
            this.$emit(`clicked`);
        },
        selectBlock(block) {
            if (!this.isEditMode)
                return;

            this.$emit(`block-selected`, block);
        },
        onSelected() {
            if (!this.isEditMode)
                return;

            if (!this.isSelected) {
                this.selectBlock(this.block);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-block {
    position: relative;

    &.fixed-top {
        position: fixed;
    }
    &.sticky-top {
        position: sticky;
    }
    outline-offset: -2px !important;

    &.fill {
        background: var(--scheme-bg);
    }

    &.card {
        background: none;
        box-shadow: none;
    }

    & :is(a) {
        text-decoration: none !important;
    }

    &.editable {
        cursor: pointer;

        &:after {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border: 2px solid var(--accent-color);
            opacity: 0;
            transition: opacity 100ms;
            pointer-events: none;
        }

        &.selected {
            &:after {
                opacity: 1 !important;
            }
        }

        &:hover {
            &:after {
                opacity: 0.8;
            }
        }
    }

    .cms-block {
        padding: 0;
        outline-offset: 0 !important;
    }

    .row .col {
        .cms-block {
            width: 100%;
        }
    }

    .editor-overlay {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(#eeeeee, 0.9);
        color: #444;
        padding: 3px 6px;
        z-index: 2;
    }
}
</style>
